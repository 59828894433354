html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
  color: #333;
  width: 100%;
  box-sizing: border-box;
}

.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 25px;
  padding-top: 1px;
}

.category-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.category-card {
  border: 1px solid #cecdcd;
  border-radius: 8px;
  padding: 10px;
  margin: 16px;
  text-align: center;
  width: 180px;
}

.category-card img {
  width: 80px;
  height: 80px;
}

.category-card a {
  text-decoration: none;
  color: inherit;
}

.category-card a img {
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border:1px solid #eeeeee;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.category-card h3 {
  text-decoration: none;
  margin-bottom: 8px;
  font-size: 15px;
  font-weight: bold;
  transition: color 0.3s ease;
  opacity: 1;
  transition: opacity 0.3s ease;
  color: #333;
}

.category-card h3:hover {
  color: #1e8e61;
  transition: color 0.3s ease;
}

.category-page {
  text-align: center;
}

.banner {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  height: 320px;
}

.banner .navigation {
  position: absolute;
  left: 20px;
  top: 16px;
  z-index: 999;
  display: flex;
  margin: 0 auto;
  background-color: #ffffff;
  opacity: .95;
  border-radius: 50%;
  padding: 3px;
}

.banner .navigation .fa {
  color: #010101;
  cursor: pointer;
}

.banner img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 0 0 36px 36px;
}
.banner-home {
  position: relative;
  width: 100%;
  max-width: 800px;
  height: 390px; /* Hauteur fixe */
  margin: 0 auto;
  background-image: url("https://unhappy-scarlet-deer.myfilebase.com/ipfs/QmfUqtQbTQinU7U2ycduztBUE75SWhrPTBi5khSWpt5QJn"); /* Remplace par ton image */
  background-size: cover; /* Remplit le conteneur en recadrant si nécessaire */
  background-position:0% 69%; /* Centre l'image */
  background-repeat: no-repeat;
  border-radius: 0 0 36px 36px;
  margin-bottom:25px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-primary:hover {
  background-color: #1e8e61;
  transition: background-color 0.3s ease;
  opacity: 0.8;
}

.button-primary {
  background-color: #0e0c11;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-danger {
  background-color: #ff3333;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.button-danger:hover {
  background-color: #9f1717;
  transition: background-color 0.3s ease;
  opacity: 0.8;
}

.banner-header {
  width: 100%;
  background-color: #333;
}

.progress-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background-color: #f1f1f1;
  z-index: 1000;
}

.progress-bar {
  width: 100%;
  height: 100%;
  background-color: #4caf50;
  animation: progress 5s linear infinite;
}

@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.App-form {
  background-color: #e3e3e3;
  border-radius: 16px 16px 0 0;
  position: fixed;
  z-index: 999;
  bottom: 0px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin:0 auto;
  padding-bottom:15px;
}

.App-form textarea,input {
  width: 100%;
  padding: 10px;
  border: 3px solid #d8d7d7;
  border-radius: 16px;
  margin-bottom: 16px;
  resize: vertical;
  font-size: 16;
  line-height: 1.5;
  box-sizing: border-box;
}

.search-section {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center
}
.search-bar {
  width: 80%;
  max-width: 320px;
  padding: 10px;
  border: 1px solid #d8d7d7;
  border-radius: 8px;
  margin-bottom: 16px;
  resize: vertical;
  font-size: 16;
  line-height: 1.5;
  box-sizing: border-box;
}

.logo {
  position: fixed;
  top: 15px;
  left: 15px;
  cursor: pointer;
  z-index: 1001;
}

.logo img {
  width: 55px;
  height: 55px;
  border-radius: 40%;
}

.sidebar {
  position: fixed;
  top: 0;
  left: -280px;
  width: 280px;
  height: 100%;
  background-color: #0d0d0d;
  color: white;
  transition: left 0.3s ease;
  z-index: 1000;
  padding-top: 40px;
}

.sidebar.open {
  left: 0;
}

.sidebar-content {
  padding: 20px;
  padding-top: 40px;
}

.sidebar-content a {
  text-decoration: none;
  color: inherit; /* Inherit the color from the parent element */
  display: block; /* Make the link take the full width of the container */
  padding: 10px; /* Add some padding for better click area */
  transition: background-color 0.3s ease; /* Add a transition for smooth hover effect */
}

.title {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* Media Query for Mobile Devices */
@media (max-width: 768px) {
  .logo {
    position: absolute;
    top: 15px;
    left: 15px;
    cursor: pointer;
    z-index: 1001;
  }
  .banner .navigation {
    left: 19px;
    top:80%;
    padding:2px;
    font-size:13px!important;
  }
  .banner {
    height: 230px!important;
    width: 100%!important;
  }
  .banner-home {
    position: relative;
    width: 100%;
    max-width: 800px;
    height: 230px; /* Hauteur fixe */
    margin: 0 auto;
    background-size: cover; /* Remplit le conteneur en recadrant si nécessaire */
    background-position: center; /* Centre l'image */
    background-repeat: no-repeat;
    border-radius: 0 0 22px 22px;
    margin-bottom:25px;
  }
  .category-card {
    border: 1px solid #cecdcd;
    border-radius: 8px;
    padding: 10px;
    margin: 5px;
    text-align: center;
    width: 100%;
  }
  .App-form {
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 100%;
    max-width: 100%;
    padding: 15px;
    box-sizing: border-box;
  }

  .App-form textarea {
    width: 100%;
    box-sizing: border-box;
  }

  .category-card {
    border: 1px solid #cecdcd;
    border-radius: 8px;
    padding: 10px;
    margin: 16px;
    text-align: center;
    width: 100%;
  }
}

/* src/components/CategoryPage.css */
.chat-container {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.tutorial-section {
  margin-top: 25px;
  margin-bottom: 120px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: justify;
  padding-bottom: 8px;
  height: auto;
    /* Ajout du scroll */
    max-height: 350px; /* Ajuste selon tes besoins */
    overflow-y: auto; /* Active le scroll vertical si nécessaire */
}

.tutorial-welcome-section {
  margin-top: 25px;
  padding: 15px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: justify;
  padding-bottom: 10px;
  margin-bottom: 90px !important;

  /* Ajout du scroll */
  max-height: 300px; /* Ajuste selon tes besoins */
  overflow-y: auto; /* Active le scroll vertical si nécessaire */
}

.category-card {
  border: 1px solid #cecdcd;
  border-radius: 8px;
  padding: 10px;
  margin: 16px;
  text-align: center;
  width: 180px;
  background-color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.category-card a {
  text-decoration: none;
  color: inherit;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category-image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  border: 1px solid #eeeeee;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.category-title {
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  color: #333;
  transition: color 0.3s ease;
}

.category-title:hover {
  color: #1e8e61;
}

/* Responsiveness */
@media (max-width: 768px) {
  .category-card {
    width: 100%;
    margin: 10px;
  }
  .tutorial-welcome-section {
    margin-top: 25px;
    padding: 15px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: justify;
    padding-bottom: 10px;
    margin-bottom: 90px !important;

    /* Hauteur dynamique */
    max-height: calc(100vh - 20px);
    overflow-y: auto; /* Ajoute un scroll si nécessaire */
  }
}

pre {
  background-color: #EEE;
  padding:6px;
  border-radius:5px;
}

.chatbot-assistant {
  width: 90%;
  border-radius: 8px;
  transition: height 0.3s ease;
  margin: 0 auto;
}

.chatbot-toggle {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.chatbot-bubble {
  font-size: 24px;
}

.chatbot-window {
  background-color: #2a2a2a;
  border: 1px solid #1c1c1c;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  height: auto;
  max-height: 390px;
  display: flex;
  flex-direction: column;
}

.chatbot-messages {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.message {
  margin-bottom: 11px;
  padding: 8px;
  border-radius: 8px;
}

.message.user {
  background-color: #515351;
  align-self: flex-end;
}

.message.bot {
  background-color: #527d6b;
  align-self: flex-start;
}

.message.bot a {
  color: #144514;
  text-decoration: none;
  border-bottom:1px dashed #144514;
}

.chatbot-input {
  display: block;
  border-top: 1px solid #515351;
  width: 100%;
}

.chatbot-input textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 0 0 4px 4px;
  margin-bottom: 8px;
  margin: 0 auto;
  box-sizing: border-box;
}

.chatbot-input button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #0e0c11;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
  display: block;
  margin: 0 auto;
}

.chatbot-title {
  color: #fff;
  font-size:13px;
  text-align: center;
}

.chatbot-input button:hover {
  background-color: #1e8e61;
}
